import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

// MUI
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material'
import Slide from '@mui/material/Slide'

// Assets
import pjsHeadshot from '../../assets/headshot-3-trans.png'
import pjsFunImage from '../../assets/balancing-trans.png'
import designIcon from '../../assets/design-icon-white-circle-trans-bg.png'
import codeIcon from '../../assets/code-ring-white-circle.png'
import deployIcon from '../../assets/deploy-icon-circle-white.png'

// Helpers
import { standardButton, socialMediaButtons, arrowElement } from '../../helpers/buttons.js'
import { portfolioArray, positionChangeWidthSm, positionChangeWidthMd, navbarHeight, iconImageDimension, footerHeight, pjsBlue, pjsGreen, pjsYellow } from '../../helpers/variableDefaults.js'
import useWindowDimensions from '../../helpers/windowDimensions.js'
import { videoInsert } from '../../helpers/videos.js'
import { portfolioItemsMobileStepperAndArrows } from '../../helpers/mobileSteppers.js'
import { spin, rock, shakeX, shakeY } from '../../helpers/keyframeAnimations.js'

import { seoPageTags, customAnalyticsEvent } from '../../helpers/analytics.js'

// Home Page
const Home = () => {

  // use Naviage
  const navigate = useNavigate()

  // Window Dimensions
  const { height, width } = useWindowDimensions()

  // States
  const [portfolioIndex, setPortfolioIndex] = useState(0)
  const [slideDirection, setSlideDirection] = useState('right')

  // Ref
  const slideRef = useRef(null)

  // Portfolio Arrow Navigation
  // Back Arrow Click Handler
  const handleBack = () => {
    if (portfolioIndex > 0) {
      setSlideDirection('right')
      setPortfolioIndex(portfolioIndex - 1)
    } 
  }

  // Back Arrow
  const backArrow = arrowElement(
    'back',
    portfolioIndex === 0, 
    handleBack,
    'whitesmoke',
    portfolioArray[portfolioIndex].buttonTextColor,
    portfolioArray[portfolioIndex].buttonColor
  )
  
  // Forward Arrow Click Handler
  const handleNext = () => {
    
    if (portfolioIndex < portfolioArray.length - 1) {
      setSlideDirection('left')
      setPortfolioIndex(portfolioIndex + 1)
    } 
  }

  const forwardArrow = arrowElement(
    'forward',
    portfolioIndex === portfolioArray.length - 1,
    handleNext,
    'whitesmoke',
    portfolioArray[portfolioIndex].buttonTextColor,
    portfolioArray[portfolioIndex].buttonColor
  )

  // In Case I want a useEffect at some point
  // useEffect(() => {
  // }, [])

  // Graphics Plus Text for 'Design; Code; Deploy'
  const iconImagePlusText = (image, text) => {
    return (
      <Box
        sx={{
          // backgroundColor: 'whitesmoke',
          // boxShadow: 3,
          height: '100%',
          width: width > positionChangeWidthSm ? '25%' : '80%',
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >
        {/* Image */}
        <Box
          sx={{
            height: iconImageDimension,
            width: iconImageDimension,
            backgroundImage: `url(${image})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            display: 'inline-block',
            animation: text === 'Design' ? `${shakeX(0, -10, 10, 0)} 3s infinite linear` : text === 'Code' ?  `${shakeY(0, 6, -6, 0)} 3s infinite linear` : `${spin(0, 360)} 3s infinite linear` ,
          }}
        />

        {/* Text */}
        <Typography
          sx={{
            mt: -2,
            height: '20%',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
        >
          {text}
        </Typography>
      
      </Box>
    )
  }

  // Portfolio Item in Carousel
  const portfolioItemJSX = (item) => {
    return (
      <Box
        sx={{
          mb: 2,
          backgroundColor: 'whitesmoke',
          width: width < 600 ? '90%' : '100%', 
          height: width > 900 ? '650px' : width > 650 ? '700px' : width > 420 ? '750px' : width > 370 ? '800px' : width > 325 ? '850px' : width > 315 ? '900px' : '1000px',
          minHeight: width > 900 ? '650px' : width > 650 ? '700px' : width > 420 ? '750px' : width > 370 ? '800px' : width > 325 ? '850px' : width > 315 ? '900px' : '1000px',
          borderRadius: 3,
          boxShadow: 3,
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}
      >

        {/* Logo/Spinner */}
        <Box 
          sx={{ 
            mb: 0,
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Link href={item.siteURL} className='site-url' target="_blank" >
            <Box 
              component='img' 
              src={item.spinner === 'NA' ? item.logo : item.spinner } 
              alt='logo' 
              sx={{ 
                mt: 2, mb: 0,
                height: width > 299 ? 175 : 175, 
                width: width > 299 ? 175 : 175,
                objectFit: 'scale-down', 
                backgroundColor: 'white',
                boxShadow: 5, 
                border: 3, borderRadius: '50%',
                borderColor: item.buttonTextColor, 
                '&:hover': { 
                  backgroundColor: item.logoHoverColor,
                },
              }} 
            />
          </Link>
        </Box>


        {/* Portfolio Item Description */}
        <Box
          sx={{
            // backgroundColor: 'yellow',
            mt: 1, mb: 0,
            width: '85.5%', maxWidth: '550px',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}  
        >
          <Typography
            sx={{
              width: '100%',
            }}  
          >
            {ReactHtmlParser(
              `${item.descriptionTextHTML}
              <br/><br/>
              <b>Frontend:</b> ${item.frontendTextHTML}
              <br/>
              <b>Backend:</b> ${item.backendTextHTML}
              <br/>
              <b>Deployment:</b> ${item.deployTextHTML}
              <br/>
              <b>Extras:</b> ${item.extrasTextHTML}
              `
            )}
          </Typography>
        </Box>

        {/* Button */}
        <Link href={item.siteURL} className='site-url' target="_blank" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'none' } }} >
          <Box
            sx={{
              mt: 4, mb: 4,
              width: '150px',
              height: '45px',
              backgroundColor: item.buttonColor,
              borderRadius: 3, 
              border: 3,
              borderColor: item.buttonTextColor,
              boxShadow: 3,
              color: item.buttonTextColor,
              fontWeight: 'bold',
              fontSize: '18px',
              '&:hover': { 
                color: item.buttonColor, 
                backgroundColor: item.buttonTextColor,
              },
              display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            }}
          >
            {item.siteName}
          </Box>
        </Link>
      </Box>
    )
  }

  return (
    <>
      {/* Helmet — for analytics, seo, and page title changing */}
      {seoPageTags(
        'Home'
      )}

      {/* Body */}
      <Box 
        sx={{ 
          minHeight: `calc(100vh - ${navbarHeight} - ${footerHeight})`, 
          width: '100vw', 
          backgroundColor: 'whitesmoke',
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', 
        }}
      >
        {/* Hero */}
        <Box
          sx={{
            mb: 0,
            width: '100%',
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          {/* Headshot */}
          <Box 
            sx={{ 
              mb: 2, 
              // mt: 5,
              // backgroundColor: 'yellow',
              // boxShadow: 2,
              height: width > 299 ? 300 : 220, 
              animation: `${rock(0, 10, -10, 0)} 5s infinite linear`,
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center',
            }}
          >
            <Box 
              component='img' 
              src={pjsHeadshot} 
              alt='Headshot' 
              sx={{ 
                height: width > 299 ? 220 : 175, 
                width: width > 299 ? 220 : 175,
                objectFit: 'cover', 
                backgroundColor: 'white',
                // backgroundColor: 'orange',
                boxShadow: 5, 
                border: 3, borderRadius: '50%',
                borderColor: 'black', 
                // animation: `${rock(0, 10, -10, 0)} 3s infinite linear`,
              }} 
            />
          </Box>

          {/* Name */}
          <Typography
            textAlign={'center'}
            sx={{
              mt: 0, pb: 0, width: '90%', 
              fontSize: '20px',
              fontWeight: 'bold',
              color: pjsGreen,
            }}
          >
            Philip Sopher
          </Typography>

          {/* Title */}
          <Typography
            textAlign={'center'}
            sx={{
              mt: 0, mb: 0, width: '90%', 
              fontSize: '16px',
              fontWeight: 'bold',
              color: pjsBlue,
            }}
          >
            Full Stack Web Developer
          </Typography>

          {/* Tagline */}
          <Typography
            textAlign={'center'}
            sx={{
              mt: 0, mb: 0, width: '90%', 
              fontSize: '16px',
              fontWeight: 'bold',
              color: pjsYellow,
            }}
          >
            Ideas to MVPs
          </Typography>

        </Box>

        {/* Icons */}
        <Box 
          sx={{
            mt: 0, mb: width > positionChangeWidthSm ? 3 : 3,
            width: '100%', maxWidth: '600px',
            height: width > positionChangeWidthSm ? '150px' : '430px',
            // backgroundColor: 'yellow',
            display: 'flex', flexDirection: width > positionChangeWidthSm ? 'row' : 'column', justifyContent: 'space-around', alignItems: 'center',
          }}
        >
          {/* Design */}
          {iconImagePlusText(designIcon, 'Design')}

          {/* Code */}
          {iconImagePlusText(codeIcon, 'Code')}

          {/* Deploy */}
          {iconImagePlusText(deployIcon, 'Deploy')}
        </Box>


        {/* Introduction Video */}
        <Box
          sx={{
            mt: 0, 
            // mb: 4,
            py: width < 600 ? 3 : 5,
            width: '100%',
            // backgroundColor: pjsBlue,
            backgroundColor: 'gray',
            boxShadow: 3,
            // borderRadius: '5px',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          <Box
            sx={{
              pb: 6, 
              pt: 3,
              width: '90%',
              maxWidth: '600px',
            }}
          >
            {videoInsert('https://www.youtube.com/embed/oywzLonLSfI', 'Introducing SimCap', '100%', width)}
          </Box>
        </Box>

        {/* Portfolio Carousel */}
        <Box
          ref={slideRef}
          sx={{
            pb: 4, pt: 6,
            backgroundColor: 'white',
            overflow: 'hidden',
            width: '100%',
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
          }}
        >
          {/* Back Arrow (Large Screen) */}
          {width >= positionChangeWidthSm && backArrow}

          {/* Between Arrows Portfolio */}
          <Box
            sx={{
              width: '100%', maxWidth: '600px',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >

            {/* Title */}
            {/* <Typography
              sx={{
                mb: 3,
                fontWeight: 'bold',
                fontSize: '24px',
              }}
            >
              Portfolio
            </Typography> */}

            {/* Portfolio Items */}
            {portfolioArray.map((item, itemIndex) => (
              <Slide 
                key={itemIndex} 
                container={slideRef.current} 
                in={portfolioIndex === itemIndex} 
                direction={slideDirection} 
                timeout={600} //Slide duration in milliseconds
                exit={false} 
                mountOnEnter 
                unmountOnExit
              >
                {portfolioItemJSX(item)}
              </Slide>
            ))}

            {/* Mobile Stepper and Arrows */}
            {portfolioItemsMobileStepperAndArrows(
              width, 
              portfolioIndex, 
              portfolioArray.length,
              backArrow,
              forwardArrow,
              portfolioArray[portfolioIndex].buttonTextColor
            )}
          </Box>

          {/* Forward Arrow (Large Screen) */}
          {width >= positionChangeWidthSm && forwardArrow}

        </Box>


        {/* My Story */}
        <Box
          sx={{
            py: 8, 
            width: '100%', 
            backgroundColor: 'lightgray',
            // backgroundColor: pjsYellow,
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
          }}
        >
          <Box
            sx={{
              px: 2, py: 2,
              width: '95%', maxWidth: '600px',
              borderRadius: 3,
              boxShadow: 3,
              backgroundColor: 'white',
              display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
            }}
          >
            {/* Fun Image */}
            <Box 
              sx={{ 
                mb: 1,
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
              }}
            >
              <Box 
                component='img' 
                src={pjsFunImage} 
                alt='Headshot' 
                sx={{ 
                  height: width > 299 ? 175 : 175, 
                  width: width > 299 ? 175 : 175,
                  objectFit: 'cover', 
                  backgroundColor: 'white',
                  boxShadow: 5, 
                  border: 3, borderRadius: '50%',
                  borderColor: 'black', 
                  animation: `${rock(0, 10, -10, 0)} 10s infinite linear`,
                  // borderColor: pjsGreen,
                }} 
              />
            </Box>

            {/* Story Text */}
            <Box
              sx={{
                // backgroundColor: 'yellow',
                mt: 2, mb: 2,
                width: '90%', maxWidth: '550px',
                display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center',
              }}  
            >
              <Typography
                sx={{
                  width: '100%',
                }}  
              >
                {ReactHtmlParser(
                  `My name is Philip Sopher, and no, I'm not a philosopher, but I did write novels for several years. Currently, I'm 36 and living in Paris, coding from cafes as a freelance web developer and cofounder of <a href='https://simcap.vc' target='_blank' rel='noreferrer' style="color:${pjsBlue}; font-weight: bold;">SimCap.vc</a>, a web platform for simulating venture capitalist decisions.
                  <br/><br/>
                  <b>My specialty is turning ideas for software products into MVPs.</b> I have designed, coded, and launched software products ranging from basic Wix pages to Shopify brand pages all the way to mobile apps and full stack websites.
                  <br/><br/>
                  Beyond software engineering, my passions include racket sports, kitesurfing, and salsa. At Princeton I was on the Varsity Squash Team, and at St. Albans School in Washington DC I was captain of the Varsity Soccer Team. A man of the world, I hold American and French passports, I've traveled to all 4 tennis grand slams, and I've visited all 7 Wonders of the World.
                  `
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Home